/* Data Table */

/* Column Accessor Keys */

export const GOAL_PRIORITY_PROBABILITY_OF_SUCCESS: any = {
    Need: '85%',
    Want: '70%',
    Wish: '60%',
    Dream: '50%'
};

export const MONTHS_TRASLATION_MAP: any = {
    January: 'TEXT_MONTH_JANUARY',
    February: 'TEXT_MONTH_FEBRUARY',
    March: 'TEXT_MONTH_MARCH',
    April: 'TEXT_MONTH_APRIL',
    May: 'TEXT_MONTH_MAY',
    June: 'TEXT_MONTH_JUNE',
    July: 'TEXT_MONTH_JULY',
    August: 'TEXT_MONTH_AUGUST',
    September: 'TEXT_MONTH_SEPTEMBER',
    October: 'TEXT_MONTH_OCTOBER',
    November: 'TEXT_MONTH_NOVEMBER',
    December: 'TEXT_MONTH_DECEMBER'
};

export const ACCESS_LEVEL_OPTIONS = (t: any) => [
    {
        value: 'advisor',
        label: t('TEXT_ADMIN_USER_FA_HEADER')
    },
    {
        value: 'admin',
        label: t('TEXT_ADMIN_USER_FA_ADMIN_HEADER')
    }
];

export const RISK_PROFILE_MAP: any = {
    conservative: 'LABEL_CONSERVATIVE',
    'somewhat conservative': 'LABEL_SOMEWHAT_CONSERVATIVE',
    moderate: 'LABEL_MODERATE',
    'somewhat aggressive': 'LABEL_SOMEWHAT_AGGERESSIVE',
    aggressive: 'LABEL_AGGERESSIVE',
    'conservatively moderate': 'LABEL_MODERATELY_CONSERVATIVE',
    'moderately aggressive': 'LABEL_MODERATELY_AGGRESSIVE'
};

export const RISK_PROFILE_API_MAP: any = {
    LABEL_CONSERVATIVE: 'conservative',
    LABEL_SOMEWHAT_CONSERVATIVE: 'somewhat conservative',
    LABEL_MODERATELY_CONSERVATIVE: 'conservatively moderate',
    LABEL_MODERATELY_AGGRESSIVE: 'moderately aggressive',
    LABEL_MODERATE: 'moderate',
    LABEL_SOMEWHAT_AGGERESSIVE: 'somewhat aggressive',
    LABEL_AGGERESSIVE: 'aggressive'
};

export const OKTA_DEVICE_TOKEN_KEY = document?.location?.host + '-' + 'device-token';

export const TENANT_ID_MAP: any = {
    advisorportaldev: 'default',
    advisorportaltest: 'default',
    advisorportaluat: 'default',
    advisorportal: 'default',

    highpointdev: 'highpoint',
    highpointtest: 'highpoint',
    highpointuat: 'highpoint',
    highpoint: 'highpoint',

    fondikadev: 'fondika',
    fondikatest: 'fondika',
    fondikauat: 'fondika',
    fondika: 'fondika',

    segurosatlasdev: 'segurosatlas',
    segurosatlastest: 'segurosatlas',
    segurosatlasuat: 'segurosatlas',
    segurosatlas: 'segurosatlas',

    faceadvisorsdev: 'faceadvisors',
    faceadvisorstest: 'faceadvisors',
    faceadvisorsuat: 'faceadvisors',
    faceadvisors: 'faceadvisors',

    'advisorportaltrial.us1': 'trailus1',

    'advisorportaltrial.latam1': 'trailla1',

    'ustrial-min': 'ustrialmin',
    'ustrial-tad': 'ustrialtad',
    'ustrial-nel': 'ustrialnel',

    galiciadev: 'galicia',
    galiciatest: 'galicia',
    galiciauat: 'galicia',
    galicia: 'galicia',

    banorteuat: 'banorte',
    banorte: 'banorte',

    advicedev: 'advice',
    advicetest: 'advice',
    adviceuat: 'advice',
    advice: 'advice'
};

export const SESSION_STORAGE_USER_INFO_KEY = 'goeap-user-info';

export const ADMIN_MANAGE_TRANSLATION_TABLE_ACCESS_KEYS = {
    ID: 'id',
    LOOK_UP_KEY: 'lookupKey',
    LOOK_UP_VALUE: 'lookupValue',
    DESCRIPTION: 'description'
};

export const USERS_TABLE_ACCESS_KEYS = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    ACCESS_LEVEL: 'accessLevel',
    USERNAME: 'username',
    EMAIL_ADDRESS: 'emailAddress',
    LAST_LOGIN: 'lastLogin',
    ID: 'id'
};

export const ADMIN_USERS_TABLE_ACCESS_KEYS = {
    ADVISOR_NAME: 'advisorName',
    CLIENT_FULL_NAME: 'clientFullName',
    CLIENT_EMAIL: 'clientEmail',
    GOALS: 'goals',
    PORTFOLIO_VALUE: 'portfolioValue',
    LAST_MODIFIED: 'lastModified',
    ADVISOR_ID: 'advisorId'
};

export const ACCESSOR_KEYS = {
    MANAGER_NAME: 'managerName',
    ADVISOR_NAME: 'advisorName',
    CLIENT_NAME: 'clientName',
    CLIENT_FULL_NAME: 'clientFirstName',
    RISK_TOLERANCE: 'riskProfile',
    GOAL_PRIORITY: 'goalPriority',
    GOAL_NAME: 'goalName',
    GOAL_AMOUNT: 'goalAmount',
    INITIAL_INVESTMENT: 'initialInvestment',
    GOALS: 'goals',
    SCORE: 'score',
    LAST_MODIFIED: 'lastModified',
    EQUITY_FIXED_INCOME: 'equityFixedIncome',
    ID: 'id',
    ACTUAL_VALUE: 'actualValue'
};

export const FINANCIAL_ADVISORS_ACCESS_KEYS = {
    ADVISOR_NAME: 'advisorName',
    ADVISOR_EMAIL: 'advisorEmail',
    NO_OF_CLIENTS: 'numberOfClients',
    NO_OF_GOALS: 'numberOfGoals',
    REMOVE: 'remove'
};

export const ADMIN_USER_ACCESS_KEYS = {
    NAME: 'adminName',
    EMAIL: 'adminEmail',
    REMOVE: 'remove'
};

/* Locales */
export const NO_DATA_FOUND = 'No Data Found';
export const NO_GOAL_FOUND = 'No Goal Found';
export const NO_RESULTS_FOUND = 'TEXT_NO_RESULTS_FOUND';

/* Pagination */
export const PAGINATION = {
    PAGE_LIMIT: 15,
    PAGE_NUMBER_INITIAL: 1
};

/* Sorting */
export const SORTING = {
    ASC: 'asc',
    DESC: 'desc',
    NO_SORT: 'no_sort'
};

/* Charts */

export const EQUITY = 'Equity';
export const FIXED_INCOME = 'Fixed Income';

/* Do not modifiy the below constants [ It is api returned key ] */
export const portfolio_PATH = 'Portfolio Path';

export const GOAL_PRIORITY: any = {
    WANT: 'Want',
    NEED: 'Need',
    WISH: 'Wish',
    DREAM: 'Dream'
};

export const GOAL_PRIORITY_TRANSLATION_MAP: any = {
    Want: 'LABEL_GOAL_PRIORITY_WANT', //'Quiero',
    Need: 'LABEL_GOAL_PRIORITY_NEED', //'Necesito',
    Wish: 'LABEL_GOAL_PRIORITY_WISH', //'Deseo',
    Dream: 'LABEL_GOAL_PRIORITY_DREAM' //'Sueño'
};

/* Search */

export const SEARCH_LABEL = 'Search Clients';

/** ***General constants */
export const TRUE = 'true';
export const FALSE = 'false';
export const ADD_CLIENT_ANNUAL = 'Annually';
export const ADD_CLIENT_MONTH = 'Monthly';
export const INFUSION_ANNUAL = 'yearly';
export const INFUSION_MONTHLY = 'monthly';
export const Add_Client_InvalidDate = 'Invalid Date';
export const ADD_CLIENT_INVESTMENT_GOAL = 'Investment Goal';
export const ADD_CLIENT_SAVING_GOAL = 'Saving Goal';
export const ADD_CLIENT_RISK_TOLERANCE = 'Risk Tolerance';
export const ADD_CLIENT_CLIENT_INFO = 'Client Info';
export const ADD_CLIENT_FUTURE_GOAL = 'Save for a Future Event';
export const ADD_CLIENT_INCOME_GOAL = 'Generate Income';
export const ADD_CLIENT_RETIREMENT_GOAL = 'Retirement Savings';
export const RETIREMENT = 'retirement';
export const REGULAR = 'regular';
export const GOAL_TYPE = {
    FUTURE_EVENT: 'Save for a Future Event',
    RETIREMENT: 'Retirement Savings',
    GENERATE_INCOME: 'Generate Income'
};

export const GOAL_TYPE_TRANSLATION_MAP = {
    'Save for a Future Event': 'TEXT_FUTURE_EVENT_OR_ACTIVITY',
    'Retirement Savings': 'TEXT_RETIREMENT_SAVINGS',
    'Generate Income': 'TEXT_GENERATE_INCOME'
};

export const GOAL_FORM_ID = {
    [GOAL_TYPE.FUTURE_EVENT]: 'future-event-form',
    [GOAL_TYPE.RETIREMENT]: 'retirement-form',
    [GOAL_TYPE.GENERATE_INCOME]: 'generate-income-form'
};

export const FREQUENCY_TRANSLATION_MAP: any = {
    Annually: 'TEXT_ANNUALLY',
    Monthly: 'TEXT_MONTHLY'
};

export const WITHDRAWAL_FREQUENCY = {
    YEARLY: 'Annually',
    MONTHLY: 'Monthly'
};
export const SCHEDULED_FREQUENCY = {
    YEARLY: 'Annually',
    MONTHLY: 'Monthly'
};

export const FORMAT = {
    DATE: 'DD-MM-YYYY',
    MONTH: 'MM-DD-YYYY',
    YEAR: 'YYYY-MM-DD'
};

export const RECOMMENDED = {
    PROB_75: 'recommendedProbAt75%',
    PROB_50: 'recommendedProbAt50%',
    WEALTH_50: 'recommendedFinalWealthAt50%',
    WEALTH_75: 'recommendedFinalWealthAt75%'
};

export const LOGIN = {
    MFA_CHALLENGE: 'MFA_CHALLENGE',
    MFA: 'MFA',
    SUCCESS: 'SUCCESS',
    MFA_REQUIRED: 'MFA_REQUIRED',
    OKTA: 'OKTA'
};

export const TIME_PICKER_OPTIONS = [
    {
        label: '12:00 AM',
        value: '00:00:00'
    },
    {
        label: '01:00 AM',
        value: '01:00:00'
    },
    {
        label: '02:00 AM',
        value: '02:00:00'
    },
    {
        label: '03:00 AM',
        value: '03:00:00'
    },
    {
        label: '04:00 AM',
        value: '04:00:00'
    },
    {
        label: '05:00 AM',
        value: '05:00:00'
    },
    {
        label: '06:00 AM',
        value: '06:00:00'
    },
    {
        label: '07:00 AM',
        value: '07:00:00'
    },
    {
        label: '08:00 AM',
        value: '08:00:00'
    },
    {
        label: '09:00 AM',
        value: '09:00:00'
    },
    {
        label: '10:00 AM',
        value: '10:00:00'
    },
    {
        label: '11:00 AM',
        value: '11:00:00'
    },
    {
        label: '12:00 PM',
        value: '12:00:00'
    },
    {
        label: '01:00 PM',
        value: '13:00:00'
    },
    {
        label: '02:00 PM',
        value: '14:00:00'
    },
    {
        label: '03:00 PM',
        value: '15:00:00'
    },
    {
        label: '04:00 PM',
        value: '16:00:00'
    },
    {
        label: '05:00 PM',
        value: '17:00:00'
    },
    {
        label: '06:00 PM',
        value: '18:00:00'
    },
    {
        label: '07:00 PM',
        value: '19:00:00'
    },
    {
        label: '08:00 PM',
        value: '20:00:00'
    },
    {
        label: '09:00 PM',
        value: '21:00:00'
    },
    {
        label: '10:00 PM',
        value: '22:00:00'
    },
    {
        label: '11:00 PM',
        value: '23:00:00'
    }
];
